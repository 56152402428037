
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from "vue";
export default defineComponent({
  name: "DemoView",
  components: {},
  setup() {
    const { proxy }: any = getCurrentInstance();
    let data = reactive({
      IsCollapse: true,
      fullScreen: false,
    });
    const FnHandleOpen = function (key: any, keyPath: any) {
      console.log(key, keyPath);
    };
    const FnHandleClose = function (key: any, keyPath: any) {
      console.log(key, keyPath);
    };
    const FnChangeIsCollapse = function () {
      data.IsCollapse = !data.IsCollapse;
    };
    function FnDemo() {
      console.log("FnDemo ====>", proxy);
    }
    function FnInitJitsimeet() {
      const JitsiMeetingObj = (window as any).JitsiMeetExternalAPI;
      const domain = "jitsimeet.15584312340.cn";
      const options = {
        roomName: "测试房间",
        width: 700,
        height: 700,
        parentNode: document.querySelector("#meet"),
      };
      const api = new JitsiMeetingObj(domain, options);
    }
    onMounted(() => {
      // FnDemo();
      // FnInitJitsimeet();
      // proxy.$Bus.$on("Magnify", (val: any) => {
      //   console.log("val 的改变 ===>", val);
      //   data.fullScreen = val;
      // });
    });
    return {
      ...toRefs(data),
      proxy,
      FnHandleOpen,
      FnHandleClose,
      FnChangeIsCollapse,
      FnDemo,
    };
  },
});
